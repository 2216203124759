#navbarSupportedContent{
  flex-flow:row-reverse;
}
.bg-body-tertiary{
  background: #f6f6f6!important;
}
.navbar-brand{
  font-weight: 700;
  text-transform: uppercase;
  color: #d93e40;
}
.nav-link{
  font-size: 16px;
  font-weight: 600;
  padding: 8px 16px!important;
}

/*--banner--*/
.post-banner{
  padding: 30px 0;
}

.card{
  border-radius: 10px;
  overflow: hidden;
  border: none;
}
.card_thumbnail{
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.card_thumbnail img {
  width: 100%;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.card_content {
  padding: 0 40px;
  padding-bottom: 40px;
  position: absolute;
  bottom: 0;
  width: auto;
  z-index: 2;
  left: 0;
  right: 0;
}
.card_content .card-content {
  background: transparent !important;
  padding: 0;
}
.card_content .card_cat {
  margin-bottom: 10px;
}
.card_content .card-cat .card_list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: -8px;
}
.card_content .card_cat .card_list a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0;
  position: relative;
  font-weight: 500;
  text-decoration: none;
  color:#f0f2f5;
}
.card_content .card_title a {
  position: relative;
  display: inline;
  background-image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
  background-image: -webkit-linear-gradient(left, currentColor 0%, currentColor 100%);
  background-image: -o-linear-gradient(left, currentColor 0%, currentColor 100%);
  background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 2px;
  background-position: 0px 95%;
  -webkit-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  -o-transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  padding: 0.1% 0px;
  background-repeat: no-repeat;
  color: inherit;
  font-size: 30px;
  text-decoration: none;
  color:#fff;
}
.card:hover .card_thumbnail img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.content-block .card_content{
  position: relative;
  padding: 0;
}
.content-block img{
  border-radius: 10px;
  overflow: hidden;
}
.content-block .post_cat {
  margin-bottom: 10px;
}
.content-block .hover_flip_item_wrapper {
  color:#d93e40!important;
}
.content-block .card_title a{
  color: #000;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
}

/*--trendng gallery--*/
.trending_gallery{
  padding: 30px 0 60px 0;
}
.trending_card{
  position: relative;
  outline: none;
  margin-bottom: 0;
}
.trending_card .inner {
  position: relative;
  overflow: hidden;
  border-radius:10px;
}
.trending_card .inner::before {
  position: absolute;
  content: "";
  opacity: 0.4;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 2;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.trending_card .inner a {
  display: block;
}
.trending_card .inner:hover .thumbnail img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.trending_card .thumbnail img {
  width: 100%;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 10px;
  display: block;
}
.gallery_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.gallery_title h1{
  font-size: 32px;
  color: #000;
  font-weight: 700;
}
.gallery_title a{
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
}

.trending_card .content {
  padding-left: 5px;
  padding-right: 5px;
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  padding-bottom: 22px;
  z-index: 2;
}
.trending_card .content .title {
  margin-bottom: 0;
  color: #fff;
}

/*---post list area---*/
.post_list_area{
  background: #F0F2F5;
  padding: 60px 0;
}

.list_card_block{
  display: flex;
  margin-bottom: 20px;
  border-radius: 10px;
  background: #fff;
  padding: 20px;
}
.list_card_block:hover .list_card_img img{
  transform: scale(1.1);
}
.list_card_img img{
  transition: 0.5s;
}
.list_card_block .list_card_img{
  border-radius: 10px;
  overflow: hidden;
  display: block;
  width: 65%;
}

.list_card_content{
  margin-left: 30px;
}
.list_card_content span {
  font-size: 14px;
  font-weight: 500;
  color: #d93e40;
}
.list_card_content h3{
  margin: 10px 0 15px 0;
  text-decoration: none;
  font-size: 20px;
  line-height: 24px;
}
.list_card_content h3 a{
  color: #09101f!important;
}
.list_card_content p {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #656565;
}
a{
  text-decoration: none;
}
a:hover{
  text-decoration: underline;
}

/*---latest news---*/
.latest_news,
.popular_post{
  margin-left: 20px;
}
.latest_news h2,
.popular_post h2 {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
  font-size: 25px;
  font-weight: 500;
}
.latest_news h2::before,
.popular_post h2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100px;
  height: 1px;
  bottom: -1px;
  background: #d93e40;
}

.news_block{
  display: flex;
  gap: 15px;
  margin: 10px 0;
}
.news_block .thumb{
  width: 25%;
  overflow: hidden;
}
.news_block .thumb img{
  border-radius: 5px;
}
.news_block a{
  width: 120px;
  height: auto;
}

.news_block .info{
  width: 75%;
}
.news_block .info .title{
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 15px;
  font-weight: bold;
}
.news_block .info .title a{
  color: #09101f;
}
.news_block .info p{
  display: block;
  margin-top: 5px;
  color: #656565;
  font-weight: 500;
  font-size: 14px;
}

/*--tags--*/
.tags{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.tags a{
  display: block;
  padding: 8px 15px;
  border: solid 1px #09101f;
  border-radius: 5px;
  color: #09101f;
  font-size: 14px;
  font-weight: 500;
}
.tags a:hover{
  border: solid 1px #d93e40;
 background: #d93e40;
 text-decoration: none;
 color: #fff;
}

.footer_area{
  position: relative;
  padding: 50px 0 30px 0;
}
.social_link{
  display: flex;
  gap: 20px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: solid 1px #e5e6e7;
}
ul.social-icon {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
ul.social-icon li {
  margin: 5px;
  list-style: none;
}
ul.social-icon li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #050505;
    border-radius: 100%;
    background: #f0f2f5;
    display: block;
    text-align: center;
    font-size: 14px;
}
ul.social-icon li a:hover {
  background:#d93e40;
  color: #fff;
}

.footer_navbar_copyright{
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.footer_navbar{
  display: flex;
  gap: 40px;
}
.footer_logo{
  font-weight: 700;
  text-transform: uppercase;
  color: #d93e40;
}

ul.footer_menu{
  display: flex;
  padding:0px 20px; 
  margin: 0;
  gap: 20px;
}
ul.footer_menu li {
  list-style: none;
}
ul.footer_menu li a{
  color: #878787;
  font-size: 16px;
  line-height: 22px;
  display: block;
  font-weight: 500;
}

/*---about---*/
.banner-style {
  height: 370px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding-bottom: 80px;
  position: relative;
  background-image: url(https://new.axilthemes.com/demo/template/blogar/assets/images/bg/bg-image-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}
.banner-style::before {
  position: absolute;
  content: "";
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(26, 26, 26, 0)), to(#1A1A1A));
  background: -webkit-linear-gradient(top, rgba(26, 26, 26, 0) 0%, #1A1A1A 100%);
  background: -o-linear-gradient(top, rgba(26, 26, 26, 0) 0%, #1A1A1A 100%);
  background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1A1A1A 100%);
  opacity: 0.8;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
}
.banner-style .inner {
  position: relative;
  z-index: 2;
}
.banner-style .inner .title {
  color: #ffffff;
  margin-bottom: 20px;
}
.banner-style .inner .description {
  margin-bottom: 0;
  color: #ced0d4;
}

.about_post_list_area{
  position: relative;
  background: #F0F2F5;
  padding: 50px 0;
}

.form-group{
  margin-bottom: 20px;
}


@media screen and (max-width:992px) {
  .card_content {
    padding: 0 15px;
    padding-bottom: 15px;
  }
  .card {
    margin-bottom: 15px;
  }
  .card_content .card_title a{
    font-size: 20px;
  }
  .card_content .card_cat .card_list a {
    font-size: 12px;
  }
  .gallery_title h1 {
    font-size: 20px;
  }
  .trending_card {
    margin-bottom: 15px;
  }
  .trending_card .content .title {
    font-size: 18px;
  }
  .trending_card .content {
    padding-bottom: 10px;
  }
  .trending_gallery {
    padding: 0px 0 20px 0;
  }
  .post_list_area,
  .footer_area {
    padding: 30px 0;
  }
  .list_card_block {
    display: grid;
  }
  .list_card_content {
    margin-left: 0;
    margin-top: 5px;
  }
  .list_card_content h3 {
    margin: 5px 0 10px 0;
    font-size: 16px;
    line-height: 22px;
  }
  .list_card_content p{
    margin-bottom: 10px;
  }
  .latest_news, .popular_post {
    margin-left: 0;
  }
  .latest_news h2, .popular_post h2 {
    font-size: 20px;
    font-weight: 600;
  }
  .tags a {
    font-weight: 600;
    font-size: 14px;
  }

  .banner-style {
    height: 270px;
    padding-bottom: 30px;
  }
  .about_post_list_area {
    padding: 30px 0;
  }
}